import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import { CATEGORIES_ROUTE } from "@routes";

import CategoriesGridList from "components/CategoriesGridList";
import GridList from "components/GridList";
import PageTitle from "components/PageTitle";

import ProductItem from "templatesComponents/Category/ProductItem";
import CategoriesItem from "pagesComponents/Categories/CategoriesItem";

import BlankPage from "./BlankPage";
import PrivateLayout from "layouts/PrivateLayout";

const Category = ({ data }) => {
  const { title } = data.contentfulCategory;

  const productsByCategories = data.allContentfulProductByCategories
    ? data.allContentfulProductByCategories.edges
    : [];
  const productsByCategory = data.allContentfulProductByCategory
    ? data.allContentfulProductByCategory.edges
    : [];

  const products = [...productsByCategories, ...productsByCategory];

  const subcategories = data.allContentfulSubcategory
    ? data.allContentfulSubcategory.edges
    : [];

  if (products.length === 0 && subcategories.length === 0) {
    return (
      <BlankPage title={title} link="Volver a categorías" to={CATEGORIES_ROUTE}>
        Oops, esta categoría está vacía.
      </BlankPage>
    );
  }

  if (subcategories.length > 0) {
    return (
      <PrivateLayout metaTitle={title}>
        <PageTitle title={title} />
        <CategoriesGridList>
          {subcategories.map(({ node }) => (
            <CategoriesItem
              key={node.slug}
              {...node}
              image={<Img fixed={node.image.fixed} />}
            />
          ))}
        </CategoriesGridList>
      </PrivateLayout>
    );
  }

  return (
    <PrivateLayout metaTitle={title}>
      <PageTitle title={title} />
      <GridList>
        {products.map(({ node }) => (
          <ProductItem
            key={node.slug}
            {...node}
            image={<Img fixed={node.images[0].fixed} />}
          />
        ))}
      </GridList>
    </PrivateLayout>
  );
};

Category.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Category;

export const query = graphql`
  query ($id: String!) {
    contentfulCategory(id: { eq: $id }) {
      title
    }
    allContentfulSubcategory(
      filter: { category: { id: { eq: $id } } }
      sort: { order: DESC, fields: parsedUpdatedAt }
    ) {
      edges {
        node {
          title
          slug
          image {
            fixed(height: 480, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
    allContentfulProductByCategories: allContentfulProduct(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { order: DESC, fields: parsedUpdatedAt }
    ) {
      edges {
        node {
          title
          slug
          price
          images {
            fixed(width: 276, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          items {
            title
          }
        }
      }
    }
    allContentfulProductByCategory: allContentfulProduct(
      filter: { category: { id: { eq: $id } } }
      sort: { order: DESC, fields: parsedUpdatedAt }
    ) {
      edges {
        node {
          title
          slug
          price
          images {
            fixed(width: 276, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          items {
            title
          }
        }
      }
    }
  }
`;
