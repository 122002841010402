import React from "react";
import PropTypes from "prop-types";

import { GridContainer, GridItem } from "./CategoriesGridList.styles";

const CategoriesGridList = ({ children }) => (
  <GridContainer>
    {children.map((child, index) => (
      <GridItem key={index}>{child}</GridItem>
    ))}
  </GridContainer>
);

CategoriesGridList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default CategoriesGridList;
