import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 100%;

  &:hover {
    ${() => Overlay} {
      opacity: 1;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.white}50;
  opacity: 0;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Name = styled.h2`
  font-size: 48px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  text-transform: uppercase;
  text-decoration: none;
`;
