import styled from "@emotion/styled";

export const GridContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  list-style-type: none;

  padding: 0;
  margin-top: 26px;
  margin-bottom: 26px;

  @media (min-width: 580px) {
    margin: 16px -10px 16px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 14px -12px 14px;
  }

  @media (min-width: 1000px) {
    justify-content: flex-start;
  }
`;

export const GridItem = styled.li`
  width: 480px;
  max-width: 100%;

  > * {
    max-width: 100%;
  }

  text-align: center;
  background-color: #fff;

  margin-bottom: 34px;

  overflow: hidden;

  @media (min-width: 580px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 350px;

    margin-left: 12px;
    margin-right: 12px;
  }

  @media (min-width: 1000px) {
    width: 460px;
  }

  @media (min-width: 1040px) {
    width: 480px;
  }
`;
