import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Container, Overlay, Name } from "./CategoriesItem.styles";

import withLocation from "components/withLocation";

const CategoriesItem = ({ title, slug, image, location }) => (
  <Link to={`${location.pathname}/${slug}`}>
    <Container>
      {image}
      <Overlay>
        <Name>{title}</Name>
      </Overlay>
    </Container>
  </Link>
);

CategoriesItem.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withLocation(CategoriesItem);
